import { useTranslation } from "react-i18next"
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"

import velocity from "../img/velocity.png"
import { COLORS } from "../constants/constants"

const numberFormat = new Intl.NumberFormat()

const VelocityBookContent = ({ points }) => {
  const { t } = useTranslation()

  const howToBookVelocityLink = t("links.howToBookVelocityLink")

  return (
    <Flex flexDirection={"column"} gap={5}>
      <Flex justify={"space-between"} gap={"42px"} alignItems={"center"}>
        <Box>
          <Image
            src={velocity}
            height={"42px"}
            alt={"velocity_frequent_flyer_logo"}
          />
        </Box>
        <Box>
          <Text fontSize={"md"} fontWeight={"bold"} whiteSpace={"nowrap"}>
            {numberFormat.format(points)} {t("table.bookPoints")}
          </Text>
          <Text align={"right"} fontWeight={"bold"}>
            {t("table.bookTaxes")}
          </Text>
        </Box>
      </Flex>
      <Button
        as="a"
        href={howToBookVelocityLink}
        target="_blank"
        w={"100%"}
        height={"36px"}
        backgroundColor={COLORS.red}
        color="white"
        borderRadius={8}
        boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.24)"}
      >
        {t("table.bookButton")}
      </Button>
    </Flex>
  )
}

export default VelocityBookContent
