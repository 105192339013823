import { useState, useEffect, useCallback } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Box, Heading, Text, Flex } from "@chakra-ui/react"

import { trackPage } from "../services/analytics"
import { useAuthContext } from "../services/auth"
import {
  getAustralianFlights,
  getAustralianOriginAirports,
} from "../services/api"
import FilterPanel from "../components/AustralianFlights/FilterPanel"
import Footer from "../components/Footer"
import AustralianFlightDetail from "../components/AustralianFlights/FlightDetail"
import "../App.css"
import { COLORS, PROGRAMS_OPTIONS, SUBSCRIPTION } from "../constants/constants"
import { toFromAustraliaSeoTags } from "../helpers/seoTags"
import useDebugger from "../hooks/useDebugger"
import { pickBy } from "lodash"

const { FREE } = SUBSCRIPTION

const DEFAULT_FILTERS = {
  from: { label: "Australia", value: "Australia" },
  to: { label: "Europe", value: "Europe" },
  program: { label: "All", value: "All" },
}

const ToFromAustralia = () => {
  useDebugger()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { user } = useAuthContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const [flightsTitleText, setFlightsTitleText] = useState(null)
  const [orderBy, setOrderBy] = useState("departure_date")
  const [descending, setDescending] = useState("False")
  const [filters, setFilters] = useState({
    from: null,
    to: null,
    program: null,
  })

  const isFreePlan =
    !user || [FREE, null].includes(user?.subscription.toLowerCase())

  const params = pickBy({
    page: searchParams.get("page") || "1",
    from: filters.from?.value || null,
    to: filters.to?.value || null,
    program: filters.program?.value || null,
    page_size: 15,
    order_by: orderBy,
    desc: descending,
  })

  const { data: origins } = useQuery({
    queryKey: ["australianOriginAirports"],
    queryFn: getAustralianOriginAirports,
    initialData: {},
    keepPreviousData: true,
    enabled: true,
  })

  const transformData = (data) => {
    return Object.keys(data).map((region) => ({
      label: region,
      value: region,
      options: data[region].map((city) => ({
        label: city,
        value: city,
      })),
    }))
  }

  const selectOptions = useCallback(() => {
    return origins && Object.keys(origins).length ? transformData(origins) : []
  }, [origins])

  const { data, isFetching } = useQuery({
    queryKey: ["australianFlights", params],
    queryFn: getAustralianFlights,
    keepPreviousData: true,
    initialData: { count: 0, results: [] },
    enabled: !!(params.from && params.to),
  })

  const flights = data?.results || []
  const count = data?.count || 0

  useEffect(() => {
    if (filters.from?.label && filters.to?.label) {
      setFlightsTitleText(
        `Reward flights from ${filters.from.label} to ${filters.to.label}`
      )
    }
  }, [filters.from?.label, filters.to?.label])

  useEffect(() => {
    trackPage({ title: "To From Australian Flights" })
  }, [])

  const applyFilters = (newFilters) => {
    const updatedFilters = { ...filters, ...newFilters }
    setFilters(updatedFilters)

    setSearchParams({
      from: updatedFilters.from.value,
      to: updatedFilters.to.value,
      program: updatedFilters.program.value,
      page: "1",
    })

    navigate(
      `/all-reward-flights?from=${updatedFilters.from.value}&to=${
        updatedFilters.to.value
      }&program=${updatedFilters.program.value}&page=${"1"}`
    )
  }

  useEffect(() => {
    const fromValue = searchParams.get("from")
    const toValue = searchParams.get("to")
    const program = searchParams.get("program")

    const findMatch = (value) => {
      for (const option of selectOptions()) {
        if (option.value.toLowerCase() === value.toLowerCase()) {
          return {
            label: option.label,
            value: option.value,
          }
        }
        const subOptionMatch = option.options.find(
          (subOption) => subOption.value.toLowerCase() === value.toLowerCase()
        )
        if (subOptionMatch) {
          return subOptionMatch
        }
      }
      return null
    }

    if (selectOptions().length) {
      if (fromValue && toValue && program) {
        const fromMatch = findMatch(fromValue)
        const toMatch = findMatch(toValue)
        const programMatch = PROGRAMS_OPTIONS.find(
          (option) => option.value === program
        )

        if (fromMatch && toMatch && programMatch) {
          setSearchParams({
            from: fromMatch.value,
            to: toMatch.value,
            program: programMatch.value,
            page: searchParams.get("page") || "1",
          })

          setFilters((prevFilters) => {
            return {
              ...prevFilters,
              from: fromMatch,
              to: toMatch,
              program: programMatch,
            }
          })
        }
      } else {
        setSearchParams({
          from: DEFAULT_FILTERS.from.value,
          to: DEFAULT_FILTERS.to.value,
          program: DEFAULT_FILTERS.program.value,
          page: searchParams.get("page") || "1",
        })
        setFilters(DEFAULT_FILTERS)
      }
    }
  }, [searchParams, setSearchParams, selectOptions])

  return (
    <>
      {toFromAustraliaSeoTags()}

      <Flex
        bg={COLORS.grey}
        flexGrow={1}
        minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box>
          <Box px={{ base: 4, lg: 0 }}>
            <Heading
              as={"h1"}
              align={"left"}
              pb={2}
              color={COLORS.black}
              fontSize={{ base: "xl", lg: "2xl" }}
            >
              {flightsTitleText}
            </Heading>
            <Text
              align={"left"}
              color={COLORS.black}
              pb={6}
              fontSize={{ base: "small", lg: "sm" }}
            >
              {t("allAustralianFlightsDescription")}
            </Text>
          </Box>

          <Box
            bg={{ base: "initial", lg: "white" }}
            borderRadius={[0, 12]}
            mb={7}
          >
            <FilterPanel
              onChange={applyFilters}
              {...filters}
              isFreePlan={isFreePlan}
              selectOptions={selectOptions()}
            />

            <AustralianFlightDetail
              isFetching={isFetching}
              flights={flights}
              user={user}
              count={count}
              orderBy={orderBy}
              descending={descending}
              setOrderBy={setOrderBy}
              setDescending={setDescending}
            />
          </Box>
        </Box>

        <Box px={{ base: 4, lg: 0 }}>
          <Footer />
        </Box>
      </Flex>
    </>
  )
}

export default ToFromAustralia
